import { isMyProfileSet, setMyProfileId } from '../my'
import config from '@/config'
import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import { initGA } from '../GAEvent'
import { prepareAwardConfig } from '../awards/awardsConfig'
import { isPanelType } from '../../modules/app/actions'
import { clearAllPosters } from '../posters/clearAllPosters'
import { isDatingApp } from '../appConfigUtil'
import { datingNeedProfile } from '../../modules/posts/signals/postLike'

const { userloaderUrl } = config

export async function loadMyData(uid, params, get) {
  try {
    const response = await fetch(userloaderUrl + '/api/v1/userloader', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uids: [uid] })
    })

    if (response && response.ok) {
      const respObj = await response.json()
      const userData = { ...params, ...respObj[uid] }
      userData.name = getState('auth.name')
      userData.multi_buffs = getState('auth.multi_buffs')
      userData.storage = getState('auth.storage')
      const allUsers = getState('app.users') || {}

      allUsers[uid] = userData
      setStoreWrapper('app.users', allUsers)
      if (userData.gender === 'female') {
        setStoreWrapper('auth.female', true)
      } else if (!userData.gender || userData.gender === 'male') {
        setStoreWrapper('auth.female', false)
      }

      let profile
      if (userData && userData.profile_post && userData.profile_post.postId) {
        profile = true
        setMyProfileId(userData.profile_post.postId)
      } else if (userData && userData.storage && userData.storage.profilePost && userData.storage.profilePost.postId) {
        profile = true
        setMyProfileId(userData.storage.profilePost.postId)
      }
      setStoreWrapper('allMyDataLoaded', true)
      setStoreWrapper('genderSelectScreen', false)

      if (isPanelType('genderSelectPanel')) {
        // closeCurrentPanel()
        callSequence('app.destroyPanelImmediately')
      }

      clearAllPosters()
      if (isDatingApp() && !isMyProfileSet()) {
        setStoreWrapper('auth.isAuthComplete', true)
        datingNeedProfile('need_set_profile')
      } else {
        setStoreWrapper('auth.isAuthComplete', true)
        callSequence('posts.getMorePosts')
      }
      callSequence('auth.loadSpam')
      prepareAwardConfig(true)

      initGA({ get })
    }
  } catch (e) {
    console.log('@@@ myDataLoadError', e)
  }
}
